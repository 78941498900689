import React from "react";
import LoadingIndicator from "./LoadingIndicator";

const FullScreenWaitingBarrier = () => {
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="text-white text-2xl">
        <LoadingIndicator color="bg-white" />
      </div>
    </div>
  );
};

export default FullScreenWaitingBarrier;
