import React, { useEffect, useState, useRef } from "react";
import { formatDate } from "../../../utils/date_helpers";
import { getPlayerProfiles } from "../../../utils/api";
import LoadingIndicator from "../../../components/LoadingIndicator";

const kickTypeColors = {
  1: "bg-blue-200", // Goal Kick
  2: "bg-slate-200",
  3: "bg-slate-200",
  4: "bg-slate-200",
  5: "bg-slate-200",
};

const KickInfoPopup = ({ kick, onClose }) => {
  const [playerProfile, setPlayerProfile] = useState(null);
  const popupRef = useRef(null); // Ref for the popup element
  const [position, setPosition] = useState({ left: "0px", top: "0px" });

  const getOrdinalSuffix = (n) => {
    const s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  useEffect(() => {
    const fetchPlayer = async () => {
      try {
        const player = await getPlayerProfiles(kick.player);
        setPlayerProfile(player);
      } catch (error) {
        console.error("Failed to fetch player profile:", error);
      }
    };

    fetchPlayer();
  }, [kick.player]);

  useEffect(() => {
    if (popupRef.current) {
      const popupRect = popupRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      let newLeft = (kick.x1 / 100) * viewportWidth;
      let newTop = (kick.y1 / 100) * viewportHeight;

      // Adjust horizontal position if the popup goes off-screen
      if (newLeft + popupRect.width > viewportWidth) {
        newLeft = viewportWidth - popupRect.width - 10; // 10px padding from the right
      } else if (newLeft < 0) {
        newLeft = 10; // 10px padding from the left
      }

      // Adjust vertical position if the popup goes off-screen
      if (newTop + popupRect.height > viewportHeight) {
        newTop = viewportHeight - popupRect.height - 10; // 10px padding from the bottom
      } else if (newTop < 0) {
        newTop = 10; // 10px padding from the top
      }

      setPosition({ left: `${newLeft}px`, top: `${newTop}px` });
    }
  }, [kick, playerProfile]);

  return (
    <div
      ref={popupRef}
      className="absolute bg-white shadow-lg p-4 rounded border z-50 w-1/2"
      style={{
        left: position.left,
        top: position.top,
        transform: "translate(-50%, -100%)", // Adjust the pop-up position
      }}
    >
      <div className="flex justify-between items-top text-slate-500">
        {formatDate(kick.created_at)}
        <button onClick={onClose} className="text-red-500">
          x
        </button>
      </div>
      <div className="flex justify-between items-center">
        {playerProfile ? (
          <div className="flex">
            <div className="rounded-full bg-gray-300 w-10 h-10 mr-4 overflow-hidden flex justify-center items-center">
              <img
                src={playerProfile.image}
                alt={playerProfile.name}
                className="object-cover min-w-full min-h-full"
              />
            </div>
            <div>
              <p className="text-md">{playerProfile.name}</p>
              <p className="text-md text-slate-400">
                {getOrdinalSuffix(kick.kick_order)} kick in session
              </p>
              {kick.opta_kick && (
                <p className="text-xs text-gray-400">
                  Opta Kick ID: {kick.opta_kick}
                </p>
              )}
            </div>
          </div>
        ) : (
          <LoadingIndicator />
        )}
      </div>
      <div className="mt-2 flex flex-wrap">
        <div
          className={`flex items-center text-sm p-2 m-1 w-32 rounded-md ${
            kick.success ? "bg-green-200" : "bg-red-200"
          } ${kick.success ? "text-green-800" : "text-red-800"}`}
        >
          <div
            className={`rounded-full  w-2 h-2 mr-2 ${
              kick.success ? "bg-green-800" : "bg-red-800"
            }`}
          ></div>
          {kick.result_type}
        </div>
        <div
          className={`text-sm p-2 m-1 w-32 items-center  flex rounded-md ${
            kickTypeColors[kick.kick_type]
          }`}
        >
          {kick.kick_type_title}
        </div>

        {kick.kick_type === 5 && (
          <div
            className={`flex items-center text-sm p-2 m-1 w-32 rounded-md ${
              kick.success ? "bg-green-200" : "bg-red-200"
            } ${kick.success ? "text-green-800" : "text-red-800"}`}
          >
            <div
              className={`rounded-full  w-2 h-2 mr-2 ${
                kick.success ? "bg-green-800" : "bg-red-800"
              }`}
            ></div>

            <p>
              {kick.success ? `${Math.ceil(kick.distance_gained)}M Gain` : "0M"}
            </p>
          </div>
        )}
        <p className="text-sm p-2 m-1 w-32 bg-yellow-200 text-yellow-800 rounded-md">
          {kick.zone}
        </p>
        <p className="text-sm text-yellow-800">{kick.type}</p>
      </div>
    </div>
  );
};

export default KickInfoPopup;
