import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import React from "react";
import Layout from "./components/Layout";
import Login from "./views/Login/Login";
import Dashboard from "./views/Dashboard/Dashboard";
import { isValidToken } from "./utils/auth";
import Session from "./views/Session/Session";
import AdminPage from "./views/Admin/Admin";
import CompareProfiles from "./views/Analysis/CompareProfiles";
import UploadKicksPage from "./views/Admin/UploadKicks";

// ProtectedRoute component
const ProtectedRoute = ({ children }) => {
  const tokenValid = isValidToken();
  return tokenValid ? children : <Navigate to="/login" replace />;
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            index
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route path="login" element={<Login />} />
          <Route
            path="admin"
            element={
              <ProtectedRoute>
                <AdminPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="analysis"
            element={
              <ProtectedRoute>
                <CompareProfiles />
              </ProtectedRoute>
            }
          />
          <Route
            path="session"
            element={
              <ProtectedRoute>
                <Session />
              </ProtectedRoute>
            }
          />
          <Route
            path="upload-kicks"
            element={
              <ProtectedRoute>
                <UploadKicksPage />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
