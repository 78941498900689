// src/pages/AdminPage.js
import React, { useState, useEffect } from "react";
import UserForm from "./components/UserForm";
import UserFormEdit from "./components/UserFormEdit";
import { FaEdit } from "react-icons/fa";
import { getGroups, getPlayerProfiles, getUsers } from "../../utils/api";
import Modal from "../../components/Modal";
import ProfileImage from "./components/ProfileImage";
import { Link } from "react-router-dom";
import UploadKicksPage from "./UploadKicks";

const AdminPage = () => {
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [playerProfiles, setPlayerProfiles] = useState([]);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isEditFormVisible, setIsEditFormVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null);

  const fetchUsers = async () => {
    const _users = await getUsers();
    console.log("users: ", _users);
    setUsers(_users);
  };

  const fetchGroups = async () => {
    const _groups = await getGroups();
    console.log("groups: ", _groups);
    setGroups(_groups);
  };

  const fetchPlayerProfiles = async () => {
    const _playerProfiles = await getPlayerProfiles();
    console.log("playerProfiles: ", _playerProfiles);
    setPlayerProfiles(_playerProfiles);
  };

  useEffect(() => {
    fetchUsers();
    fetchGroups();
    fetchPlayerProfiles();
  }, []);

  const handleAddUserClick = () => {
    setIsFormVisible(true);
  };

  const handleEditUserClick = (user, profile) => {
    setSelectedUser(user);
    setSelectedProfile(profile);
    setIsEditFormVisible(true);
  };

  const [selectedTab, setSelectedTab] = useState("users");

  const renderContent = () => {
    if (selectedTab === "users") {
      return (
        <>
          <div className="flex justify-between items-center mb-8">
            <h3 className="text-xl font-semibold">Users</h3>
            <button
              onClick={handleAddUserClick}
              className="py-2 px-4 bg-kicker-green-700 text-white font-semibold rounded-md shadow-sm hover:bg-kicker-green-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              + User
            </button>
          </div>

          <Modal
            isVisible={isFormVisible}
            onClose={() => setIsFormVisible(false)}
          >
            <UserForm
              fetchPlayerProfiles={fetchPlayerProfiles}
              fetchUsers={fetchUsers}
              groups={groups}
              setIsFormVisible={setIsFormVisible}
            />
          </Modal>
          <Modal
            isVisible={isEditFormVisible}
            onClose={() => setIsEditFormVisible(false)}
          >
            <UserFormEdit
              fetchPlayerProfiles={fetchPlayerProfiles}
              fetchUsers={fetchUsers}
              groups={groups}
              selectedUser={selectedUser}
              selectedProfile={selectedProfile}
              setIsEditFormVisible={setIsEditFormVisible}
              setSelectedUser={setSelectedUser}
              setSelectedProfile={setSelectedProfile}
            />
          </Modal>
          {users && (
            <div className="grid grid-cols-1 gap-6 mb-16">
              {users.map((user) => {
                const profile = playerProfiles.find(
                  (profile) => profile.user === user?.id
                );

                return (
                  <div
                    key={user.id}
                    className="bg-white p-2 rounded-lg shadow-md flex items-center justify-between"
                  >
                    <div>
                      <div>
                        {playerProfiles.length > 0 ? (
                          (() => {
                            return profile ? (
                              <div>
                                <ProfileImage
                                  key={profile.user}
                                  profile={profile}
                                />
                                <p>{profile.name}</p>
                              </div>
                            ) : (
                              <></>
                            );
                          })()
                        ) : (
                          <></>
                        )}
                        <h2 className="text-md">{user.email}</h2>
                        {profile?.opta_player_id && (
                          <p className="text-xs p-2 text-gray-400">
                            Opta ID: {profile?.opta_player_id}
                          </p>
                        )}
                      </div>
                      <p className="text-sm text-gray-400">
                        {user.groups
                          .map(
                            (group) =>
                              group.name.charAt(0).toUpperCase() +
                              group.name.slice(1)
                          )
                          .join(", ")}
                      </p>
                    </div>
                    <button
                      className="text-gray-500 hover:text-gray-700 focus:outline-none pr-4"
                      onClick={() => handleEditUserClick(user, profile)}
                    >
                      <FaEdit size={16} />
                    </button>
                  </div>
                );
              })}
            </div>
          )}
        </>
      );
    } else if (selectedTab === "upload-kicks") {
      return <UploadKicksPage />;
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-2 flex">
      <div className="w-1/4 bg-white p-4 rounded-lg shadow-md">
        <ul>
          <li
            className={`p-2 cursor-pointer ${
              selectedTab === "users" ? "bg-gray-200" : ""
            }`}
            onClick={() => setSelectedTab("users")}
          >
            Users
          </li>
          <li
            className={`p-2 cursor-pointer ${
              selectedTab === "upload-kicks" ? "bg-gray-200" : ""
            }`}
            onClick={() => setSelectedTab("upload-kicks")}
          >
            Opta Data Uploads
          </li>
        </ul>
      </div>
      <div className="w-3/4 p-4">{renderContent()}</div>
    </div>
  );
};

export default AdminPage;
