import React from "react";

const LoadingIndicator = (color = "border-blue-500") => {
  return (
    <div className="flex items-center justify-center">
      <div
        className={`animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 ${color}`}
      ></div>
    </div>
  );
};

export default LoadingIndicator;
