import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import axios from "axios";
import { uploadKicks } from "../../utils/api";
import { UploadKicksDataTable } from "./components/UploadKicksDataTable";
import { removeUnderscoresAndCapitalize } from "../../utils/string_helpers";
import FullScreenWaitingBarrier from "../../components/FullScreenWaitingBarrier";

function UploadKicksPage() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState("");
  const [uploadResult, setUploadResult] = useState(null);
  const [isValidCsv, setIsValidCsv] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("kicks_added");
  const [waiting, setWaiting] = useState(false);

  // Required headers as mentioned in your Django view
  const requiredHeaders = [
    "ID",
    "PLID",
    "action",
    "actionName",
    "ActionType",
    "ActionTypeName",
    "Actionresult",
    "ActionResultName",
    "UTCTime",
    "x_coord",
    "y_coord",
    "x_coord_end",
    "y_coord_end",
    "Metres",
  ];

  // Handle file selection
  const handleFileChange = (e) => {
    setError("");
    setUploadResult(null);
    setIsValidCsv(false);

    const file = e.target.files[0];
    if (!file) {
      setSelectedFile(null);
      return;
    }

    // Only accept .csv
    if (file.type !== "text/csv" && !file.name.endsWith(".csv")) {
      setError("Please select a valid CSV file.");
      setSelectedFile(null);
      return;
    }

    setSelectedFile(file);

    // Optional: parse a bit to check headers
    Papa.parse(file, {
      header: true,
      preview: 1, // Only read the first line to check headers
      complete: (results) => {
        if (results.errors.length > 0) {
          setError("Error parsing CSV: " + results.errors[0].message);
          setIsValidCsv(false);
        } else {
          const fileHeaders = results.meta.fields || [];
          // Check if all required headers are present
          const missingHeaders = requiredHeaders.filter(
            (rh) => !fileHeaders.includes(rh)
          );
          if (missingHeaders.length > 0) {
            setError(
              "CSV file is missing required headers: " +
                missingHeaders.join(", ")
            );
            setIsValidCsv(false);
          } else {
            setError("");
            setIsValidCsv(true);
          }
        }
      },
      error: (err) => {
        setError("Papa Parse error: " + err.message);
        setIsValidCsv(false);
      },
    });
  };

  // Handle the actual file upload to /upload_kicks
  const handleUpload = async () => {
    if (!selectedFile) {
      setError("No file selected.");
      return;
    }
    if (!isValidCsv) {
      setError(
        "The CSV file does not have the required headers or is invalid."
      );
      return;
    }

    try {
      setError("");
      setUploadResult(null);
      setWaiting(true);

      // Prepare form data
      const formData = new FormData();
      formData.append("file", selectedFile);

      // Adjust the base URL or config as needed for your environment.
      // If you have CSRF protection, you’ll need to include the CSRF token as well.
      const res = await uploadKicks(formData);

      setUploadResult(res);
      setWaiting(false);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        setError("Error: " + err.response.data.error);
      } else {
        setError("An error occurred while uploading the file.");
      }
      setWaiting(false);
    }
  };

  // Build a small config to show each category in a clickable list.
  const categories = [
    { key: "kicks_added", label: "Kicks Added" },
    { key: "matched_kicks", label: "Matched Kicks" },
    { key: "duplicates", label: "Duplicates" },
    { key: "no_matching_player", label: "No Matching Player" },
    { key: "matched_kicks_with_errors", label: "Matched Kicks with Errors" },
    { key: "discarded_actions", label: "Discarded Actions" },
  ];

  const currentData =
    uploadResult && selectedCategory
      ? uploadResult[selectedCategory] || []
      : [];

  useEffect(() => {
    if (
      uploadResult &&
      uploadResult.kicks_added &&
      uploadResult.kicks_added.length > 0
    ) {
      setSelectedCategory("kicks_added");
    }
  }, [uploadResult]);

  return (
    <div className="container mx-auto px-4">
      {waiting && <FullScreenWaitingBarrier />}
      <div className="max-w-md mx-auto mt-10 p-6 bg-white shadow-md rounded-lg">
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">
          Upload Kicks from Opta
        </h2>

        <input
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          className="block w-full text-sm text-gray-900 
                                 border border-gray-300 rounded-lg cursor-pointer 
                                 bg-gray-50 focus:outline-none focus:border-blue-500 
                                 file:mr-4 file:py-2 file:px-4 
                                 file:rounded-l-lg file:border-0 
                                 file:text-sm file:font-semibold 
                                 file:bg-blue-600 file:text-white 
                                 hover:file:bg-blue-700"
        />

        {error && <p className="text-red-500 mt-2 text-sm">{error}</p>}

        <button
          onClick={handleUpload}
          disabled={!isValidCsv || !selectedFile}
          className={`mt-4 inline-flex items-center px-4 py-2 text-sm font-medium
                                    rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2
                                    ${
                                      !isValidCsv || !selectedFile
                                        ? "cursor-not-allowed bg-gray-300 text-gray-600"
                                        : "bg-blue-600 hover:bg-blue-700 text-white"
                                    }`}
        >
          Upload
        </button>

        {uploadResult && (
          <div className="mt-6 bg-gray-50 p-4 rounded-md">
            <h3 className="text-xl font-semibold text-gray-800">
              Upload Results
            </h3>
            <p className="mt-2 text-gray-700">
              Status: <span className="font-medium">{uploadResult.status}</span>
            </p>
            <ul className="list-disc list-inside ml-2 mt-2 space-y-1 text-gray-700">
              {categories.map((cat) => (
                <li
                  key={cat.key}
                  className="cursor-pointer hover:underline"
                  onClick={() => setSelectedCategory(cat.key)}
                >
                  <strong>{cat.label}:</strong>{" "}
                  {uploadResult[cat.key]?.length || 0}
                </li>
              ))}
            </ul>
          </div>
        )}

        {/**
         * Show the section below the main tile ONLY if "kicks_added" has entries.
         * If you want to always show this table (for all categories),
         * remove the `showDetailsSection` check.
         */}
      </div>
      {uploadResult && (
        <div>
          <h3 className="text-lg font-semibold text-gray-800 pt-12">
            {selectedCategory &&
              removeUnderscoresAndCapitalize(selectedCategory)}
          </h3>
          <div className="mt-6 px-4 pb-72 bg-white rounded-md border border-gray-200 shadow-sm overflow-x-auto">
            <UploadKicksDataTable data={currentData} />
          </div>
        </div>
      )}
      <div className="h-72 w-full"></div>
    </div>
  );
}

export default UploadKicksPage;
