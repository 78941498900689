import { useEffect, useState } from "react";
import { getGroups, updatePlayerProfile, updateUser } from "../../../utils/api";

const UserFormEdit = ({
  selectedUser,
  selectedProfile,
  setIsEditFormVisible,
  setSelectedUser,
  setSelectedProfile,
  fetchUsers,
  fetchPlayerProfiles,
}) => {
  const [email, setEmail] = useState(selectedUser.email);
  const [name, setName] = useState(selectedProfile?.name);
  const [group, setGroup] = useState(
    selectedUser.groups && selectedUser.groups[0]
      ? selectedUser.groups[0]
      : null
  );
  const [groups, setGroups] = useState([]);
  const [error, setError] = useState(null);
  const [optaPlayerId, setOptaPlayerId] = useState(
    selectedProfile?.opta_player_id || ""
  );

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const updatedUser = {
      email: email,
      groups: group ? [group.id] : [],
    };

    try {
      await updateUser(selectedUser.id, updatedUser);
      if (selectedProfile) {
        const profileUpdate = { name: name };
        if (group && group.name.toLowerCase() === "player") {
          profileUpdate.opta_player_id =
            optaPlayerId === "" ? null : optaPlayerId;
        }
        await updatePlayerProfile(selectedProfile.id, profileUpdate);
        fetchPlayerProfiles();
      }
      fetchUsers(); // Refresh the user list
      setSelectedUser(null);
      setSelectedProfile(null);
      setEmail("");
      setGroup(null);
      setIsEditFormVisible(false);
    } catch (err) {
      setError(err.message);
    }
  };

  const fetchGroups = async () => {
    const _groups = await getGroups();
    setGroups(_groups);
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  return (
    <form
      onSubmit={handleEditSubmit}
      className="mt-4 bg-white p-4 rounded-md shadow-md"
    >
      {selectedProfile && (
        <div className="mb-4">
          {error && <p className="text-red-600">{error}</p>}
          <label className="block text-gray-700">Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none"
          />
        </div>
      )}
      <div className="mb-4">
        {error && <p className="text-red-600">{error}</p>}
        <label className="block text-gray-700">Email</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Role</label>
        <select
          value={group ? group.name : ""}
          onChange={(e) => {
            const selectedGroup = groups.find(
              (grp) => grp.name === e.target.value
            );
            setGroup(selectedGroup || null);
          }}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none"
        >
          <option value="">Select a role</option>
          {groups.map((group) => (
            <option key={group.name} value={group.name}>
              {group.name.charAt(0).toUpperCase() + group.name.slice(1)}
            </option>
          ))}
        </select>
      </div>
      {group && group.name.toLowerCase() === "player" && (
        <div className="mb-4">
          <label className="block text-gray-700">Opta Player ID</label>
          <input
            type="text"
            value={optaPlayerId}
            onChange={(e) => setOptaPlayerId(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none"
          />
        </div>
      )}
      <button
        type="submit"
        className="w-full py-2 px-4 bg-green-600 text-white font-semibold rounded-md shadow-sm hover:bg-green-700"
      >
        Update User
      </button>
    </form>
  );
};

export default UserFormEdit;
