/**
 * A small helper component to render data in a generic table.
 * If the data array is empty, it shows "No items to display."
 */
export function UploadKicksDataTable({ data = [] }) {
  if (!data.length) {
    return <p className="mt-2 text-gray-500">No items to display.</p>;
  }

  // Dynamically derive columns from the first row's keys (if consistent).
  const columns = Object.keys(data[0]);

  return (
    <table className="min-w-full border border-gray-300 mt-4">
      <thead>
        <tr>
          {columns.map((col) => (
            <th
              key={col}
              className="px-4 py-2 border-b border-gray-300 text-left bg-gray-100 font-semibold"
            >
              {col}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex} className="hover:bg-gray-50">
            {columns.map((col) => (
              <td key={col} className="px-4 py-2 border-b border-gray-300">
                {row[col] !== undefined ? String(row[col]) : ""}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
