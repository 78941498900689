import React, { useEffect, useRef, useState } from "react";
import field from "../../../assets/images/session_field_1.png";
import fullField from "../../../assets/images/full_field.png";
import KickInfoPopup from "./KickInfoPopup";
import updateInitialPosition from "../../../utils/map_helpers";
import ZonePercentages from "./ZonePercentages";
import ZoneDistanceAverages from "./ZoneDistanceAverages";

const RugbyUnionFieldChart = ({
  background = false,
  kicks,
  kickTypeId,
  avgGain = 0,
}) => {
  const imgRef = useRef(null);
  const [averageGain, setAverageGain] = useState(avgGain);
  const [loaded, setLoaded] = useState(false);
  const [processedKicks, setProcessedKicks] = useState(null);
  const [zoneDistanceAverages, setZoneDistanceAverages] = useState([]);
  const [zonePercentages, setZonePercentages] = useState([]);
  const [kickForTouchZoneSelected, setKickForTouchZoneSelected] =
    useState(null);
  const [selectedKick, setSelectedKick] = useState(null);
  const [selectedZone, setSelectedZone] = useState(null);
  const [kickForTouchZones, setKickForTouchZones] = useState(null);
  const [restartZones, setRestartZones] = useState(null);

  const restartZoneLabels = {
    1: "Centre Zone 1",
    2: "Centre Zone 5",
    3: "Left Zone 2",
    4: "Left Zone 3",
    5: "Left Zone 4",
    6: "Right Zone 2",
    7: "Right Zone 3",
    8: "Right Zone 4",
  };

  const onImgLoad = () => {
    console.log("set positions : ", kickTypeId);
    if (kickTypeId === 3 || kickTypeId === 5) {
      console.log("update initial postion for ", kickTypeId);
      updateInitialPosition(
        imgRef,
        null,
        null,
        null,
        null,
        setLoaded,
        null,
        setRestartZones,
        setKickForTouchZones,
        false
      );
    }
  };

  const handleKickClick = (kick) => {
    setSelectedKick(kick);
  };

  const closePopup = () => {
    setSelectedKick(null);
  };

  const filterDataByZone = (e, id, x1, y1, x2, y2) => {
    // Clear filter if zone is tapped a second time
    if (id === kickForTouchZoneSelected) {
      setAverageGain(null);
      setKickForTouchZoneSelected(null);
      setProcessedKicks(kicks);
      return;
    }
    setKickForTouchZoneSelected(id);
    // Prevent default action
    e.preventDefault();

    // Set the selected zone for further operations
    setSelectedZone(id);

    // Assuming you have the container dimensions available
    const containerWidth = imgRef.current.offsetWidth;
    const containerHeight = imgRef.current.offsetHeight;

    // Filter the kicks based on whether they fall within the zone
    const kicksInZone = kicks.filter((kick) => {
      // Convert percentage values to pixels
      const kickX1 = (kick.x1 / 100) * containerWidth;
      const kickY1 = (kick.y1 / 100) * containerHeight;

      // Check if the kick falls within the zone
      return kickX1 >= x1 && kickX1 <= x2 && kickY1 >= y1 && kickY1 <= y2;
    });

    console.log("Kicks within the zone: ", kicksInZone);
    if (kicksInZone.length === 0) {
      setAverageGain(0); // No kicks in the zone, set average gain to 0
      return;
    }

    const distanceGained = kicksInZone.reduce(
      (gain, kick) =>
        kick.success ? gain + Math.abs(kick.distance_gained || 0) : gain, // Default to 0 if undefined
      0 // Initial value for the accumulator
    );

    const successfulKicks = kicksInZone.reduce(
      (count, kick) => count + (kick.success ? 1 : 0), // Add 1 if kick.success is true
      0 // Initial value for the accumulator
    );

    // Avoid division by zero
    const avgGain =
      successfulKicks > 0 ? Math.ceil(distanceGained / successfulKicks) : 0;

    setAverageGain(avgGain);
    setProcessedKicks(kicksInZone);
    // Do something with the filtered kicks (e.g., update state or UI)
  };

  useEffect(() => {
    let zoneStats;

    if (kickTypeId !== 3) {
      zoneStats = {
        "left 5m": { total: 0, successful: 0 },
        "left 15m": { total: 0, successful: 0 },
        center: { total: 0, successful: 0 },
        "right 15m": { total: 0, successful: 0 },
        "right 5m": { total: 0, successful: 0 },
      };
    } else {
      zoneStats = {
        1: { total: 0, successful: 0 },
        2: { total: 0, successful: 0 },
        3: { total: 0, successful: 0 },
        4: { total: 0, successful: 0 },
        5: { total: 0, successful: 0 },
        6: { total: 0, successful: 0 },
        7: { total: 0, successful: 0 },
        8: { total: 0, successful: 0 },
      };
    }

    let zoneDistanceStats = {
      "left 5m": { total: 0, distance_gained: 0 },
      "left 15m": { total: 0, distance_gained: 0 },
      center: { total: 0, distance_gained: 0 },
      "right 15m": { total: 0, distance_gained: 0 },
      "right 5m": { total: 0, distance_gained: 0 },
    };
    // Step 1: Group kicks by session_id
    const groupedKicks = kicks.reduce((acc, kick) => {
      const { session_id } = kick;
      if (!acc[session_id]) {
        acc[session_id] = [];
      }
      acc[session_id].push(kick);
      return acc;
    }, {});

    // Step 2: Sort each group by created_at and assign kick order
    const _processedKicks = Object.values(groupedKicks).flatMap(
      (sessionKicks) => {
        // Sort by created_at timestamp
        sessionKicks.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );

        // Step 3: Assign kick order
        return sessionKicks.map((kick, index) => ({
          ...kick,
          kick_order: index + 1, // Adding the kick order (1-based index)
        }));
      }
    );

    _processedKicks?.forEach((kick) => {
      let { id, distance_gained, kicking_area, zone, success } = kick;

      zone = kickTypeId === 3 ? kicking_area : zone.toLowerCase();

      if (!zoneDistanceStats[zone]) {
        zoneDistanceStats[zone] = { total: 0, distance_gained: 0 };
      }

      if (success) {
        zoneDistanceStats[zone].total += 1;
        zoneDistanceStats[zone].distance_gained += distance_gained;
      }

      if (!zoneStats[zone]) {
        zoneStats[zone] = { total: 0, successful: 0 };
      }
      zoneStats[zone].total += 1;
      if (success) zoneStats[zone].successful += 1;
    });

    const calculatedZones = Object.entries(zoneStats).map(
      ([id, { total, successful }]) => {
        // console.log("id: ", id);
        // console.log("label id: ", restartZoneLabels[id]);
        return {
          label: kickTypeId === 3 ? restartZoneLabels[id] : id,
          percentage: total === 0 ? 0 : Math.floor((successful / total) * 100),
        };
      }
    );

    const calculatedDistanceByZone = Object.entries(zoneDistanceStats).map(
      ([id, { total, distance_gained }]) => {
        return {
          label: id,
          average_gain:
            total === 0 ? 0 : Math.abs(Math.ceil(distance_gained / total)),
        };
      }
    );

    setProcessedKicks(_processedKicks);
    setZonePercentages(calculatedZones);
    setZoneDistanceAverages(calculatedDistanceByZone);
  }, [kicks, kickTypeId]);

  return (
    <div className="flex flex-col items-center bg-green-700 p-4 rounded-lg">
      {zonePercentages?.length > 0 && kickTypeId !== 5 ? (
        <ZonePercentages kickTypeId={kickTypeId} stats={zonePercentages} />
      ) : (
        <></>
      )}

      {zoneDistanceAverages?.length > 0 && kickTypeId === 5 ? (
        <ZoneDistanceAverages
          kickTypeId={kickTypeId}
          stats={zoneDistanceAverages}
        />
      ) : (
        <></>
      )}
      {averageGain ? (
        <p className="text-white text-sm">Average Gain: {averageGain}m</p>
      ) : (
        <></>
      )}
      <div
        className={`relative w-full ${
          kickTypeId == 3 && "aspect-[1.7741549621855224]"
        }`}
      >
        <img
          ref={imgRef}
          onLoad={onImgLoad}
          src={kickTypeId !== 5 ? field : fullField}
          alt="Rugby Field"
          className={
            kickTypeId == 3
              ? "absolute top-0 left-0 w-full h-full object-contain"
              : "w-full"
          }
        />
        {loaded &&
          kickTypeId === 3 &&
          restartZones &&
          restartZones.map(({ id, label, x, y, w, h }) => {
            return (
              <div
                key={id}
                style={{
                  position: "absolute",
                  left: `${x}px`,
                  top: `${y}px`,
                  width: w,
                  height: h,
                }}
                className="bg-slate-200 border border-slate-500 border-dotted flex justify-center items-center font-bold text-slate-400 text-md"
              >
                {label}
              </div>
            );
          })}
        {loaded &&
          kickTypeId == 5 &&
          kickForTouchZones?.map(({ id, label, x, y, w, h }) => {
            return (
              <div
                key={id}
                onClick={(e) => {
                  filterDataByZone(e, id, x - 2, y - 2, x - 2 + w, y - 2 + h); //making the zone overlays slightly smaller than the area so they are easier to see.
                }}
                id={id}
                style={{
                  position: "absolute",
                  left: `${x + 5}px`,
                  top: `${y + 5}px`,
                  width: w - 10,
                  height: h - 10,
                  zIndex: 0,
                  pointerEvents: "auto", // Ensure zones are interactive
                }}
                className="bg-opacity-15 bg-slate-500 border-slate-200 flex justify-center items-center font-bold text-slate-400 text-md"
              >
                {label}
              </div>
            );
          })}
        <svg
          className="absolute top-0 left-0 w-full h-full"
          style={{ zIndex: 1, pointerEvents: "none" }}
        >
          {processedKicks?.map((kick, index) =>
            kick.kick_type === 5 && kick.x2 != null && kick.y2 != null ? (
              <line
                key={index}
                onClick={(e) => {
                  e.preventDefault();
                  handleKickClick(kick);
                }}
                x1={`${kick.x1}%`}
                y1={`${kick.y1}%`}
                x2={`${kick.x2}%`}
                y2={`${kick.y2}%`}
                stroke={kick.success === true ? "#21C55D" : "#EF4444"}
                strokeWidth="2"
              />
            ) : null
          )}
        </svg>
        {processedKicks?.map((kick, index) => (
          <div
            key={index}
            onClick={() => {
              console.log("kick info: ", kick);
              handleKickClick(kick);
            }}
            className={`absolute w-2 h-2 rounded-full ${
              kick.success === true ? "bg-green-500" : "bg-red-500"
            }`}
            style={{
              left: `${kick.x1}%`,
              top: `${kick.y1}%`,
              transform: "translate(-50%, -50%)",
            }}
          ></div>
        ))}
        {processedKicks?.map((kick, index) =>
          kick.kick_type === 5 && kick.x2 && kick.y2 ? (
            <div
              key={index}
              onClick={() => {
                console.log("kick info: ", kick);
                handleKickClick(kick);
              }}
              className={`absolute w-2 h-2 rounded-full ${
                kick.success === true ? "bg-green-500" : "bg-red-500"
              }`}
              style={{
                left: `${kick.x2}%`,
                top: `${kick.y2}%`,
                transform: "translate(-50%, -50%)",
              }}
            ></div>
          ) : null
        )}
        {selectedKick && (
          <KickInfoPopup kick={selectedKick} onClose={closePopup} />
        )}
      </div>
    </div>
  );
};

export default RugbyUnionFieldChart;
